<template>
  <section class="contact-persons part" :class="[ payload.optionen ]">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 md:col-10 md:offset-1">
          <div class="persons">
            <div
              v-for="person in payload.person"
              :key="person.id"
              v-view
              class="person animate in-bottom"
            >
              <box :force-aspect-ratio="!isDesktop" :aspect="0.75" class="mb-10">
                <Image :src="person.bild" />
              </box>
              <div class="content">
                <h2 class="name" v-html="person.name" />
                <Go class="phone" :to="person.telefonnummer" v-text="person.telefonnummer" />
                <Go class="mail" :to="person.mail" v-text="person.mail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useWithinBreakpoint } from '@/composables/breakpoints';

export default {
  props: { payload: { type: Object, default: Object } },
  setup() {
    return {
      isDesktop: useWithinBreakpoint('md'),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_grid.scss";
@import "@/assets/styles/_type.scss";

.contact-persons {
  @include responsive('margin-bottom', px(82), px(295));
}

.person {
  @include responsive('width', 60vw, auto);

  &:nth-child(2n) {
    margin-left: auto;
  }
}

.contact + .contact-persons .persons {
  border-top: 1px solid var(--color-secondary);
}

.persons {
  @include responsive('padding-top', px(50), px(148));
}

h2 {
  position: relative;

  @include responsive('margin-top', px(18), 0);
  @include responsive('margin-bottom', px(16), px(39));
}

.description {
  @include responsive('margin-bottom', px(28), px(65));
}

.phone, .mail {
  @apply text-sm;
  display: block;
  @include responsive('line-height', px(22), px(26));
  @include responsive('margin-bottom', px(8), px(15));
  @include responsive('margin-left', px(22), px(33));
}

.phone {
  @include responsive('margin-bottom', px(8), px(9));
}

.mail {
  text-decoration: underline;
}

.persons {
  display: grid;
  grid-column-gap: px(80);
  @include responsive('grid-row-gap', px(84), px(120));
  @include responsive('grid-template-columns', 1fr, 1fr 1fr);

  :deep(img) {
    @apply rounded-big;
    object-fit: cover;
    object-position: top;
  }
}

.content.right {
  @include responsive('margin-right', 0, 12.5%);
  @include responsive('padding-top', px(18), px(74));
}

.right .person-row {
  .content {
    @include responsive('grid-row', unset, 1);
  }

  .quote {
    text-align: right;
  }
}
</style>
